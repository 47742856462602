/* style.css */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  overflow: hidden;
}

#root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  background: radial-gradient(circle at center, #ffffff 0%, #07296a 100%);
  transition: opacity 1s ease-in-out;
}

#root.show-gradient::before {
  opacity: 1;
}

#root.show-gradient::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: -1;
  background: radial-gradient(circle at center, rgba(105,145,248,0.5) 0%, #ffffff 100%);
  animation: expandCircle 2s forwards 1s;
}

@keyframes expandCircle {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(30);
  }
}

header {
  height: 22.5%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

.main {
  height: 59.5%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

footer {
  height: 18%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}
.mail-block {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mail-input {
  width: 34vh;
  height: 100%;
  background: #e4e5e9;
  border: 2px solid #07296A;
  color: #07296A;
  font-size: 100%;
  padding: 3px 10px;
  border-radius: 10px;
  resize: none;
  font-size: 85%;
  justify-content: center;
  align-items: center;
}

.text-review-block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-review-input {
  width: 34vh;
  height: 100%;
  background: #e4e5e9;
  border: 2px solid #07296A;
  color: #07296A;
  font-size: 100%;
  padding: 20px;
  border-radius: 15px;
  resize: none;
  font-size: 85%;
}

.send-text-review-button {
  height: 35%;
  width: 34vh;
  border-radius: 7px;
  padding: 0 20px;
  font-size: 90%;
  font-weight: bold;
  color: #07296A;
  background: #e4e5e9;
  border: none;
  cursor: pointer;
  box-shadow: 0 5px 5px #999;
}
