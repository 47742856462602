.file-list-container {
  padding: 20px;
  width: 80%;
}

.no-files {
  text-align: center;
  padding: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #6c757d;
}

.file-list {
  list-style-type: none;
  padding: 0;
}

.file-item {
  background-color: #ffffff;
  margin: 15px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.file-item:hover {
  transform: scale(1.02);
}

.file-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.filename {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
}

.filename .fa-star {
  margin-left: 10px;
}

.date {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.small-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.small-button:hover {
  background-color: #0056b3;
}

.save-edit-button {
  background-color: #28a745;
}

.save-edit-button:hover {
  background-color: #218838;
}

.edit-button {
  background-color: #ffc107;
}

.edit-button:hover {
  background-color: #e0a800;
}

.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

.text {
  margin-top: 10px;
}

.edit-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  resize: none;
}

.textarea:focus {
  border-color: #007bff;
  outline: none;
}

.star-rating {
  display: flex;
  gap: 5px;
}

.hide-sentiments {
  background-color: #ddd;
  border-radius: 15px;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hide-sentiments:hover {
  background-color: #ccc;
}

.area-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.area-tag {
  padding: 8px 15px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: transform 0.2s, background-color 0.3s ease;
}

.area-tag:hover {
  transform: translateY(-5px);
}

.selected {
  transform: translateY(-5px);
}

.area-tag.selected {
  background-color: #80c7ff;
  border-color: #5fa8e3;
}

.clear-selection {
  background-color: #ffeb3b;
  color: black;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.clear-selection:hover {
  background-color: #fdd835;
}

.insights {
  margin-top: 20px;
}

.theme {
  background-color: #f0f8ff;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid #dbe2e8;
  margin-bottom: 10px;
}

.theme-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme-header h6 {
  font-size: 16px;
  font-weight: bold;
}

.theme-area {
  font-size: 12px;
  color: #007bff;
  font-style: italic;
}

.theme-portions {
  margin-top: 10px;
}

.portion {
  margin-bottom: 15px;
}

.tags {
  margin-top: 5px;
  display: flex;
  gap: 8px;
}

.tag {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

.tag:hover {
  opacity: 0.8;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
